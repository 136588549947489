import React, { useCallback, useEffect, useState } from "react";

import { BankFieldType, CountryCode, RecipientType, Rules } from "@trolley/common-frontend";
import { Form2 as Form } from "components";
import debounce from "lodash.debounce";
import {
  AccountVerificationResultNotif,
  FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP,
  mapBankAccountQuery,
} from "pages/PayoutMethod2/BankTransfer";
import { AccountVerificationQuery } from "store/actions/bankInfo";
import { useAccountVerification } from "store/hooks/bankInfo";
import { useRecipient } from "store/hooks/recipient";
import { BaseStatus } from "store/reducers/standardReducer";

interface Props {
  countryRule: BankFieldType[];
}

export default function AccountVerificationFormField(props: Props) {
  const { countryRule } = props;
  const recipient = useRecipient();
  const [accountQuery, setAccountQuery] = useState<AccountVerificationQuery | undefined>();
  const { data: accountVerifResult, status: accountVerifStatus } = useAccountVerification(
    accountQuery,
    recipient?.type as RecipientType,
  );
  // TODO: hiding temporarily account verification to release updated Trust copies, it will be restored with next account verification PR
  // const [requiresAccountVerification, setRequiresAccountVerification] = useState(false);
  const requiresAccountVerification = false;
  const form = Form.useFormInstance();
  const currency = Form.useWatch("currency", form);
  const iban = Form.useWatch("iban", form);
  const branchId = Form.useWatch("branchId", form);
  const accountNum = Form.useWatch("accountNum", form);
  const accountHolderName = Form.useWatch("accountHolderName", form);
  const accountHolderFirstName = Form.useWatch("accountHolderFirstName", form);
  const accountHolderLastName = Form.useWatch("accountHolderLastName", form);

  // TODO: hiding temporarily account verification to release updated Trust copies, it will be restored with next account verification PR
  // useEffect(() => {
  //   const requiresVerif = !!merchant?.features?.accountVerificationUk && recipient?.address.country === CountryCode.GB;
  //   if (requiresVerif !== requiresAccountVerification) {
  //     setRequiresAccountVerification(requiresVerif);
  //   }
  // }, [merchant, recipient]);

  useEffect(() => {
    form.setFieldValue("requiresAccountVerification", requiresAccountVerification);
  }, [requiresAccountVerification]);

  useEffect(() => {
    onValuesChange();
  }, [currency, iban, branchId, accountNum, accountHolderName, accountHolderFirstName, accountHolderLastName]);

  useEffect(() => {
    if (accountVerifResult) {
      form.setFieldValue("accountVerif", accountVerifResult);
      form.validateFields(["accountVerif"]).catch(() => {
        //
      });
    }
  }, [accountVerifResult]);

  useEffect(() => {
    form.setFieldValue("accountVerifStatus", accountVerifStatus);
  }, [accountVerifStatus]);

  function onValuesChange() {
    form.resetFields(["accountVerif"]);
    setAccountQuery(undefined);
    debounceSetQuery(countryRule);
  }

  const debounceSetQuery = useCallback(
    debounce((countryRule: Rules) => {
      const fieldsValue = form.getFieldsValue();
      const errors = form.getFieldsError(FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP);
      const validValues = [...countryRule, "accountHolderFirstName", "accountHolderLastName"].every((rule) => {
        if (!FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP.includes(rule)) {
          return true;
        }

        if (!fieldsValue[rule]) {
          return false;
        }

        return !fieldsValue[rule]?.includes("*");
      });

      const hasErrors = Object.values(errors).some((item) => item.errors.length > 0); // Errors on fields that matters to the bank lookup
      if (!hasErrors && validValues && recipient?.id) {
        const mappedQuery = mapBankAccountQuery(fieldsValue, recipient.id, CountryCode.GB);
        setAccountQuery(mappedQuery ?? undefined);
      } else {
        setAccountQuery(undefined);
      }
    }, 650),
    [form],
  );

  return (
    <>
      {requiresAccountVerification &&
        accountVerifStatus &&
        accountVerifStatus !== BaseStatus.ERROR &&
        accountVerifResult && (
          <Form.Item
            name="accountVerif"
            shouldUpdate
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AccountVerificationResultNotif result={accountVerifResult} />
          </Form.Item>
        )}
      <Form.Item name="accountVerifStatus" />
      <Form.Item name="requiresAccountVerification" />
    </>
  );
}
